//=require main.js
//=require components/masonry.pkgd.min.js
//=require components/imagesloaded.pkgd.min.js

$(document).ready(function () {
    $('.outstanding-works .read-more').mouseover(function (){
        $(this).find('img').attr('src','/images/icons/arrow-read-more.svg');
    })
    $('.outstanding-works .read-more').mouseout(function (){
        $(this).find('img').attr('src','/images/icons/arrow-read-more-before.svg');
    })

    $('.slider-kts').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-exclusive-offer').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-blog').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    var options = {
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    $(window).on('scroll', function () {
        if ($(window).scrollTop() >= $('.blog').offset().top + $('.blog').outerHeight() - window.innerHeight) {
            options = {
                ...options,
                autoplay: true,
            }
            $('.slider-brand').slick(options);
        }
    });

    // $('.slider-brand').slick(options);

    $('.slider-inspiration').slick({
        slidesToShow: 7,
        slidesToScroll: 7,
        arrows: true,
        variableWidth: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slick-current span').addClass('choose');

    jQuery.fn.clickToggle = function (a, b) {
        return this.on("click", function (ev) {
            [b, a][this.$_io ^= 1].call(this, ev)
        })
    };

    //vote
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $(document).delegate('.icon-heart-not-active', 'click', function () {
        let id = $(this).data('id');
        let count_vote = parseInt($(this).data('count'));
        count_vote++
        $(this).replaceWith('<div class="icon-heart-active" data-id="' + id + '" data-count="'+ count_vote +'">' +
            '<img src="/images/icons/icon-heart-active.png" class="icon-heart">' +
            '<span class="text-center" style="color: #fff; font-size: 12px;margin-left: 5px">'+ count_vote +'</span>' +
            '</div>')
        $.ajax({
            url: '/vote-portfolio',
            method: "post",
            data: {id: id},
            beforeSend: function () {
                $('.overlay').fadeIn();
            },
            success: function () {
                $('.overlay').fadeOut();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(errorThrown, jqXHR, textStatus)
            }
        })
    })
    $(document).delegate('.icon-heart-active', 'click', function () {
        let id = $(this).data('id');
        let count_vote = parseInt($(this).data('count'));
        count_vote--
        $(this).replaceWith('<div class="icon-heart-not-active" data-id="' + id + '" data-count="'+ count_vote +'">' +
            '<img src="/images/icons/icon-heart.png" class="icon-heart">' +
            '<span class="text-center" style="color: #fff; font-size: 12px;margin-left: 5px">'+ count_vote +'</span>' +
            '</div>')
        $.ajax({
            url: '/remove-vote-portfolio',
            method: "delete",
            data: {id: id},
            dataType: 'json',
            beforeSend: function () {
                $('.overlay').fadeIn();
            },
            success: function (data) {
                console.log(data)
                $('.overlay').fadeOut();
            }
        })
    })

    !function ($, callback) {
        if (typeof $ === 'undefined') {
            $ = require('jquery');
        }
        callback($);
    }(window.jQuery, function ($) {
        $(function () {
            const $progress = $('#progress');
            const $stepDescriptionBlock = $('.step-description-block-item');
            const $prev = $('#prev');
            const $next = $('#next');
            const $circles = $('.circle');
            const $start = $('.btn-step .btn-start');
            const len = $circles.length;
            const maxSize = 768;

            let curStep = 0;

            let interval;
            const timer = function() {
                interval = setInterval(() => {
                    curStep = curStep + 1;
                    if (curStep === len) {
                        curStep = 0;
                    }

                    update();
                }, 3000)
            }

            $circles.click(function () {
                const circleStep = Number($(this).find('span').text().slice(1, 2)) - 1;

                curStep = circleStep;
                if (curStep >= len) {
                    curStep = len - 1;
                }

                clearInterval(interval);
                timer();

                update();
            });

            $prev.click(function () {
                curStep = curStep - 1;
                if (curStep <= 0) {
                    curStep = 0;
                }
                update();
            });

            function updateStatus() {
                if (curStep === 0) {
                    $prev.hide();
                    $next.show();
                    $start.addClass('d-none');
                } else if (curStep >= len - 1) {
                    $prev.show();
                    $next.hide();
                    $start.removeClass('d-none');
                } else {
                    $prev.show();
                    $next.show();
                    $start.addClass('d-none');
                }
            }

            function update() {

                $circles.removeClass('active');

                $circles.each(function (idx) {
                    const $this = $(this);

                    if (idx <= curStep) {
                        $this.addClass('active');
                    }
                });

                $stepDescriptionBlock.addClass('d-none');
                $stepDescriptionBlock.eq(curStep).removeClass('d-none');

                const progressWidth = (((curStep / (len - 1)) * 100)) + '%';

                const winWidth = $(window).width();

                $progress.css(winWidth >= maxSize ? 'width' : 'height', progressWidth);

                updateStatus();
            }

            timer();
            update();

            let resizeTimeout;
            $(window).on('resize.step', function () {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(function () {
                    $progress.removeAttr('style');
                    update();
                }, 50);
            });
        });
    });


})






